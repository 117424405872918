{
  // モーダルウィンドウの書き換え
  const modal = document.getElementById('modalPlayer')
  if (modal) {
    modal.addEventListener('show.bs.modal', event => {
      const item = event.relatedTarget
      const data = {
        "id": item.getAttribute('data-id'),
        "title": item.getAttribute('data-title'),
        "company": item.getAttribute('data-company'),
        "products": item.getAttribute('data-products'),
        "date": item.getAttribute('data-date'),
        "length": item.getAttribute('data-length'),
        "performers": item.getAttribute('data-performers'),
        "mflg": item.getAttribute('data-mflg'),
        "revisions": item.getAttribute('data-revisions'),
      }
      if (!data.id) {
        modal.querySelector('.js-player-video').src = './images/thumbnails/blank.png';
      } else {
        dir = data.id.split('-');
        if (data.mflg == 1) {
          modal.querySelector('.js-player-video').src = './mp4/' + dir[0] + '/' + data.id + '.mp4';
        } else {
          modal.querySelector('.js-player-video').src = './thumbnails/' + dir[0] + '/' + data.id + '.jpg';
        }
      }
      modal.querySelector('.js-player-company').textContent = data.company;
      modal.querySelector('.js-player-products').innerHTML = data.products.replaceAll(',', '<br>');
      modal.querySelector('.js-player-title').textContent = data.title;
      modal.querySelector('.js-player-date').textContent = data.date;
      if (!data.id) {
        modal.querySelector('.js-player-id-wrapper').classList.add('d-none');
      } else {
        modal.querySelector('.js-player-id-wrapper').classList.remove('d-none');
        modal.querySelector('.js-player-id').textContent = data.id;
      }
      modal.querySelector('.js-player-length').textContent = data.length;
      if (data.performers) {
        modal.querySelector('.js-player-performers-wrapper').classList.remove('d-none');
        modal.querySelector('.js-player-performers').textContent = data.performers;
      } else {
        modal.querySelector('.js-player-performers-wrapper').classList.add('d-none');
      }
      if (data.revisions) {
        modal.querySelector('.js-player-revisions').classList.remove('d-none');
        modal.querySelector('.js-player-revisions').textContent = data.revisions;
      } else {
        modal.querySelector('.js-player-revisions').classList.add('d-none');
      }
    })
  }
}

{
  const modal = document.getElementById('modalPlayer');
  if (modal) {
    modal.addEventListener('click', function () {
      const videoPlayer = document.getElementById('video');
      videoPlayer.pause();
    })
  }
}
