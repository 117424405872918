
{
  // 検索結果一覧 - すべて選択のチェックボックス
  const checkAll = document.querySelector(".js-list-check-all");
  if (checkAll != null) {
    const checks = document.querySelectorAll(".js-list-check");
    checkAll.addEventListener('click', () => {
      for (val of checks) {
        checkAll.checked == true ? val.checked = true : val.checked = false;
      }
    });
    checks.forEach(element => {
      element.addEventListener('click', () => {
        if (element.checked == false) {
          checkAll.checked = false;
        }
        if (document.querySelectorAll(".js-checks:checked").length == checks.length) {
          checkAll.checked = true;
        }
      });
    });
  }

  // リストの一部がチェックされている場合、全て選択のチェックボックスを indeterminate にする
  const checks = document.querySelectorAll(".js-list-check");
  checks.forEach(element => {
    element.addEventListener('click', (e) => {
      const checkAll = document.querySelector(".js-list-check-all");
      const checkedChecks = document.querySelectorAll('.js-list-check:checked');
      checkAll.indeterminate = false;
      if (checkedChecks.length == checks.length) {
        checkAll.checked = true;
      } else if (checkedChecks.length == 0) {
        checkAll.checked = false;
      } else {
        checkAll.indeterminate = true;
      }
    });
  })
}


{
  // 詳細検索 - カテゴリのチェックボックス

  // 親カテゴリがチェックされた場合、子カテゴリを全選択(または全非選択)にする
  const parentCheckBoxes = document.querySelectorAll(".js-parent-category-check");
  parentCheckBoxes.forEach(element => {
    element.addEventListener('click', (e) => {
      const childCheckBoxes = e.target.closest('.js-category-wrapper').querySelectorAll('.js-child-category-check');
      for (val of childCheckBoxes) {
        e.target.checked == true ? val.checked = true : val.checked = false;
      }
    })
  });

  // 子カテゴリが一部チェックされている場合に、親カテゴリのチェックボックスを indeterminate にする
  const checks = document.querySelectorAll(".js-child-category-check");
  checks.forEach(element => {
    element.addEventListener('click', (e) => {
      const parentCheckBox = e.target.closest('.js-category-wrapper').querySelector('.js-parent-category-check');
      const checkedChildCheckBoxes = e.target.closest('.js-category-wrapper').querySelectorAll('.js-child-category-check:checked');
      const childCheckBoxes = e.target.closest('.js-category-wrapper').querySelectorAll('.js-child-category-check');
      parentCheckBox.indeterminate = false;
      if (checkedChildCheckBoxes.length == childCheckBoxes.length) {
        parentCheckBox.checked = true;
      } else if (checkedChildCheckBoxes.length == 0) {
        parentCheckBox.checked = false;
      } else {
        parentCheckBox.checked = false;
        parentCheckBox.indeterminate = true;
      }
    });
  })
}

{
  //チェックボックスの数をカウントする(CSV用)
  const maxcnt = 1000;
  const csv_exp = document.getElementById('csv-exp');
  if (csv_exp) {
    csv_exp.addEventListener('click', (e) => {
      const checks = document.querySelectorAll(".js-list-check");
      var count = 0;
      checks.forEach(element => {
        if (element.checked == true) {
          count++;
        }
      })
      if (count > maxcnt) {
        alert("一度に出力可能な件数は" + maxcnt + "件までです。\n選択件数:" + count + "件");
        e.stopPropagation();
      } else if (count == 0) {
        alert("出力するデータを選択してください。");
        e.stopPropagation();
      } else {
        document.f_result.action = "../pages/csvExp.php";
        document.f_result.submit();
        document.f_result.action = "";
      }
    })
  }
}

{
  //チェックボックスの数をカウントする(再生用)
  const mov_play = document.getElementById('mov-play');
  if (mov_play) {
    mov_play.addEventListener('click', (e) => {
      const checks = document.querySelectorAll(".js-list-check");
      var count = 0;
      checks.forEach(element => {
        if (element.checked == true) {
          count++;
        }
      })
      if (count == 0) {
        alert("再生するCMが選択されていません。");
        e.stopPropagation();
      } else {
        document.f_result.action = "?page=player";
        document.f_result.submit();
        document.f_result.action = "";
      }
    })
  }
}



