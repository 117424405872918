import Tooltip from 'bootstrap/js/src/tooltip'
{
  // ツールチップの初期化 ref: https://getbootstrap.com/docs/5.3/components/tooltips/
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
}

{
  const cartButtons = document.querySelectorAll('.js-add-to-cart');
  cartButtons.forEach(element => {
    element.addEventListener('click', event => {
      event.preventDefault();

      const icon = event.target;
      const data = icon.dataset;
      const tooltip = Tooltip.getInstance(event.target.closest('a'));

      if (icon.classList.contains('bi-cart-plus-fill')) {
        icon.classList.remove('bi-cart-plus-fill', 'text-warning');
        icon.classList.add('bi-cart-dash-fill', 'text-secondary');
        tooltip.setContent({ '.tooltip-inner': "カートから取り除く" });

        var xhr = new XMLHttpRequest();
        var url = '../partials/addCart.php?val=' + data.id;
        xhr.open('GET', url);
        xhr.send();
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            document.getElementById('cart-cnt').textContent = xhr.responseText;
          }
        }
      } else {
        icon.classList.add('bi-cart-plus-fill', 'text-warning');
        icon.classList.remove('bi-cart-dash-fill', 'text-secondary');
        tooltip.setContent({ '.tooltip-inner': "カートに追加" });

        var xhr = new XMLHttpRequest();
        var url = '../partials/delCart.php?val=' + data.id;
        xhr.open('GET', url);
        xhr.send();
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            document.getElementById('cart-cnt').textContent = xhr.responseText;
          }
        }
      }
    });
  });
}

{
  // カートの中身チェック
  const cartBtn = document.getElementById('cart-link');
  if (cartBtn) {
    cartBtn.addEventListener('click', () => {
      const element = document.getElementById('cart-cnt');
      var cart_cnt = element.textContent;
      if (cart_cnt == 0) {
        alert("カートの中身が空です。");
        element.stopPropagation();
      } else {
        location.href = '/?page=cart';
      }
    })
  }
}

{
  // カートの中身を削除
  const cartDelBtn = document.querySelectorAll('.cart-del');
  cartDelBtn.forEach(element => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      const target = e.target.closest('button');
      const data = target.dataset;
      var xhr = new XMLHttpRequest();
      var url = '../partials/delCart.php?val=' + data.id;
      xhr.open('GET', url);
      xhr.send();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          document.getElementById('cart-cnt').textContent = xhr.responseText;

          if (xhr.responseText == 0) {
            location.href = "?page=cart&empty=1"
          } else {
            location.href = "/?page=cart"
          }
        }
      }
    });
  });
}

{
  // お客様情報の必須チェック(電話番号)
  const cartComLink = document.getElementById('cart-confirm');
  if (cartComLink) {
    cartComLink.addEventListener('click', event => {
      event.preventDefault();
      const tel = document.getElementById('tel');
      var telval = tel.value;
      if (telval.length == 0) {
        alert("電話番号を入力してください。");
        tel.stopPropagation();
      }

      /* /\d{2,4}: 2-4 桁の数字- : ハイフン/\d{2,4}: 2-4 桁の数字- : ハイフン/\d{2,4}: 2-4 桁の数字 */
      const regex = /\d{2,4}-\d{2,4}-\d{4}/;
      if (regex.test(telval) == false) {
        /* ^(0{1}: 0 から始まる \d{9,10})$: 数字 9 桁で終わる*/
        const regex = /^(0{1}\d{9,10})$/;
        if (regex.test(telval) == false) {
          /* ^0: 0 から始まる^[5789]0: 「5、7、8、9 のいずれか」と 0 
          - : ハイフン [0-9]{4}: 0〜9 の数字 4 桁 - : ハイフン [0-9]{4}: 0〜9 の数字 4 桁で終わる */
          const regex = /^0[5789]0-[0-9]{4}-[0-9]{4}$/;
          if (regex.test(telval) == false) {
            /* ^(050|070|080|090): 050 または　070 または 080 または 090 で始まる 
            - : ハイフン [0-9]{4}: 0〜9 の数字 4 桁 - : ハイフン [0-9]{4}: 0〜9 の数字 4 桁で終わる*/
            const regex = /^(050|070|080|090)-\d{4}-\d{4}$/;
            if (regex.test(telval) == false) {
              alert("電話番号の形式が違います");
              tel.stopPropagation();
            }
          }
        }
      }

      document.f_cart.action = "/?page=cart-confirm";
      document.f_cart.submit();
      document.f_cart.action = "";
    }
    )
  }
}
