{
    // 動画視聴お客様情報の必須チェック(利用日数)
    const spotBuyLink = document.getElementById('spot-buy');
    if (spotBuyLink) {
        spotBuyLink.addEventListener('click', (e) => {
            e.preventDefault();
            const period = document.getElementById('period').value;
            if (period == 0) {
                alert("ご利用日数を選択してください。");
                e.stopPropagation();
            } else {
                document.f_spotbuy.action = "/partials/issue.php";
                document.f_spotbuy.submit();
            }
        })
    }
}

{
    // メールアラート
    const applicationAlert = document.getElementById('alert-application');
    if (applicationAlert) {
        applicationAlert.addEventListener('click', () => {
            document.f_application_alert.action = "/partials/application-alert-issue.php";
            document.f_application_alert.submit();
        })
    }
}