import '../../node_modules/bootstrap/dist/js/bootstrap'
import './checkbox'
import './modal'
import './expired'
import './toast'
import './player'
import './cartButton'
import './search'
import './form'
import './alert'
