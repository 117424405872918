import { Alert } from "bootstrap";

{
    //CM検索用パラメータチェック
    const searchBtn = document.querySelectorAll('.btn-search');
    if (searchBtn != null) {
        var bun1flag = false;
        searchBtn.forEach(element => {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                var chbun1 = document.getElementsByName('bun1[]');
                for (var i = 0; i < chbun1.length; i++) {
                    if (chbun1.item(i).checked) {
                        bun1flag = true;
                    }
                }
                var bun2flag = false;
                var chbun2 = document.getElementsByName('bun2[]');
                for (var i = 0; i < chbun2.length; i++) {
                    if (chbun2.item(i).checked) {
                        bun2flag = true;
                    }
                }
                element = document.getElementById('keyword');
                var keyword = element.value;
                if ((keyword.length == 0) && (bun1flag == false) && (bun2flag == false)) {
                    alert("キーワードまたは分類を\n詳細検索より指定してください。");
                    e.stopPropagation();
                } else {
                    document.f_search.action = "../partials/set-parametar.php";
                    document.f_search.submit();
                    document.f_search.action = "";
                }
            })
        })
    }
}


{
    //CMNO検索用
    const cmnoBtn = document.getElementById("cmnoBtn");
    if (cmnoBtn != null) {
        cmnoBtn.addEventListener('click', () => {
            let element = document.getElementById('cmnoBox');
            var cmno = element.value;
            if (cmno.length == 0) {
                alert("CMNOを指定してください。");
                return false;
            } else {
                document.f_cmnoSearch.action = "/?page=search&cmno";
                document.f_cmnoSearch.submit();
            }
        })

    }
}

{
    //CCSVアップロード用
    const cmnoBtn = document.getElementById("csvBtn");
    if (cmnoBtn != null) {
        cmnoBtn.addEventListener('click', () => {
            let element = document.getElementById('fileneme');
            var cmno = element.value;
            if (cmno.length == 0) {
                alert("ファイルを指定してください。");
                return false;
            } else {
                document.f_csvUpload.action = "/partials/list-upload.php";
                document.f_csvUpload.submit();
            }
        })

    }
}
