
{
  // 動画視聴申込 - 有効期限の表示
  const select = document.querySelector('.js-application-period');
  if (!select) {
    return;
  }
  select.addEventListener('change', (e) => {
    const numberOfDays = parseInt(e.target.value);
    const alert = document.querySelector('.js-expired-time');
    if (numberOfDays === 0) {
      alert.classList.add('d-none');
      return;
    }
    const useType = document.getElementById('use-type').value;
    if (useType == 1) {
      const exDate = document.getElementById('ex_date').value;
      const arr = exDate.match(/\b\d+\b/g);
      const strExDate = arr[0] + '-' + arr[1] + '-' + arr[2] + ' ' + arr[3] + ':' + arr[4];
      var date = new Date(strExDate);
    } else {
      var date = new Date();
    }
    date.setDate(date.getDate() + numberOfDays);
    const expiredDate = date.toLocaleDateString("ja-JP", {
      year: "numeric", month: "2-digit",
      day: "2-digit"
    }).replaceAll('/', '-');
    const expiredTime = date.toLocaleTimeString("ja-JP", { hour: "2-digit", minute: "2-digit", hours12: false });

    alert.classList.remove('d-none');
    alert.querySelector('span').textContent = expiredDate + ' ' + expiredTime + ' まで有効';

  });
}
