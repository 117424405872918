import Toast from '../../node_modules/bootstrap/js/src/toast'
{
  // お知らせの toast の表示
  const toastNodes = document.querySelectorAll('.js-toast');
  if (toastNodes) {
    toastNodes.forEach((e) => {
      const toast = Toast.getOrCreateInstance(e, { autohide: false });
      toast.show()
    })
  }
}
