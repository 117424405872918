{
  // 動画連続再生 - 動画の切り替え
  const items = document.querySelectorAll('.js-playlist-item');
  if (!items) return;

  items.forEach(element => {
    element.addEventListener('click', event => {
      items.forEach(element => {
        element.classList.remove('active');
      });
      const target = event.target.closest('a');
      target.classList.add('active');

      const data = target.dataset;
      if (!data.id) {
        document.querySelector('.js-player-video').src = './thumbnails/blank.png';
      } else {
        dir = data.id.split('-');
        if (data.mflg == 1) {
          document.querySelector('.js-player-video').src = './mp4/' + dir[0] + '/' + data.id + '.mp4';
        } else {
          document.querySelector('.js-player-video').src = './thumbnails/' + dir[0] + '/' + data.id + '.jpg';
        }
      }
      document.querySelector('.js-player-id').textContent = data.id;
      document.querySelector('.js-player-company').textContent = data.company;
      document.querySelector('.js-player-products').innerHTML = data.products.replaceAll(',', '<br>');;;
      document.querySelector('.js-player-title').textContent = data.title;
      document.querySelector('.js-player-date').textContent = data.date;
      document.querySelector('.js-player-length').textContent = data.length;
      const revisions = document.querySelector('.js-player-revisions');
      if (data.revisions) {
        revisions.classList.remove('d-none');
        revisions.textContent = data.revisions;
      } else {
        revisions.classList.add('d-none');
      }
      const perfomersWrapper = document.querySelector('.js-player-performers-wrapper');
      if (data.performers) {
        perfomersWrapper.classList.remove('d-none');
        document.querySelector('.js-player-performers').textContent = data.performers;
      } else {
        perfomersWrapper.classList.add('d-none');
      }
      document.querySelector('.js-player-row').textContent = data.row;
    });
  })
};
// 動画連続再生
{
  const videoPlayer = document.getElementById('video');
  if (videoPlayer) {
    videoPlayer.addEventListener("ended", function () {
      const element = document.querySelector('.js-player-row');
      var currentRow = element.textContent;
      var nextRow = Number(currentRow) + 1;
      var idName = "row" + String(nextRow);
      var nextVideo = document.getElementById(idName);
      nextVideo.click();
      videoPlayer.play();
    });
  }
}



